import React from 'react'
import FILE_VIDEO_ICON from './video-file.png'
import FILE_REMOVE_ICON from './remove.png'
import FILE_CHECK_ICON from './check.png'

export const CardVideoInfo = ({ videoName, videoSize, showDelete, showSuccess, onDelete }) => {
  const deleteHandler = () => {
    if (onDelete) {
      onDelete()
    } else {
      console.log('Delete file');
    }
  }

  return (
    <div>
      <div className="video-file-box">
        <img src={FILE_VIDEO_ICON} />
        <div className="video-file-box-desc">
          <div>{ videoName }</div>
          <div><span>{ `Tamaño: ${videoSize}` }</span></div>
        </div>
        { showDelete && 
          (
            <button type="button" className="btn-file-remove" onClick={deleteHandler}>
              <img src={FILE_REMOVE_ICON} />
            </button>
          ) 
        }
        { showSuccess &&
          (
            <div className="btn-upload-success">
              <img src={FILE_CHECK_ICON} />
            </div>
          )
        }
      </div>
    </div>
  )
}
