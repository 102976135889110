import HTTP from "./http.service";
import ROUTES_API from "const/routes-api.const";

class SubscriberService {
  async list() {
    return await HTTP.get(ROUTES_API.SUBSCRIBERS.LIST);
  }

  async detail(subscriber) {
    return await HTTP.get(ROUTES_API.SUBSCRIBERS.DETAIL.replace(':id', subscriber));
  }

  async save(params) {
    return await HTTP.post(ROUTES_API.SUBSCRIBERS.ADD, params);
  }

  async update(params) {
    return await HTTP.put(ROUTES_API.SUBSCRIBERS.EDIT, params);
  }

  async updatePassword(id, pass) {
    let params = { subscriber: id, password: pass };
    return await HTTP.put(ROUTES_API.SUBSCRIBERS.CHANGE_PWD, params);
  }

  async delete(id) {
    return await HTTP.delete(ROUTES_API.SUBSCRIBERS.DELETE.replace(':id', id));
  }
}

const instance = new SubscriberService();
Object.freeze(instance);

export default instance;