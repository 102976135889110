import HTTP from "./http.service";
import ROUTES_API from "const/routes-api.const";

class InterviewService {
  async list() {
    return await HTTP.get(ROUTES_API.INTERVIEWS.LIST);
  }

  async detail(interview) {
    return await HTTP.get(ROUTES_API.INTERVIEWS.DETAIL.replace(':id', interview));
  }

  async save(params) {
    return await HTTP.post(ROUTES_API.INTERVIEWS.ADD, params);
  }

  async update(params) {
    return await HTTP.put(ROUTES_API.INTERVIEWS.UPDATE, params);
  }

  async upload_image(interview, params) {
    return await HTTP.post(ROUTES_API.INTERVIEWS.UPLOAD_PHOTO.replace(':id', interview), params);
  }

  async delete(interview) {
    return await HTTP.delete(ROUTES_API.INTERVIEWS.DELETE.replace(':id', interview));
  }
}

const instance = new InterviewService();
Object.freeze(instance);

export default instance;