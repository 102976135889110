import React, { useState } from 'react'
import "./searchbar.scss";

export default function Searchbar(props) {
  const [value, setValue] = useState('')
  // const [active, setActive] = useState(true)

  const handleInput = (e) => {
    setValue(e.target.value)
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  }

  const handleCancel = () => {
    setValue('')
    if (props.onChange) {
      props.onChange('')
    }
  }

  const _renderClearButton = () => {
    return (
      <button 
        type="button" 
        className="search-btn-clear" 
        onClick={handleCancel} >
        <i className="fa fa-times"></i>
      </button>
    );
  }


  return (
    <div className="search-container">
      <label>
        <i className="search-icon fa fa-search"></i>
      </label>
      <input
        className="search-input"
        type="text"
        placeholder="Buscar"
        value={value}
        onChange={handleInput} />
      {value && props.showCancelSearch && _renderClearButton()}
    </div>
  )
}