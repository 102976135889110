import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const ProgressFileVideo = ({ percentage, text }) => {

  return (
    <div className="uploader-progress-wrap">
      <div className="circle-uploader-progress-wrap">
        <CircularProgressbar maxValue={99} value={percentage} text={`${percentage}%`} />
      </div>
      <h5>{`${text}...`}</h5>
    </div>
  )
}
