class LocalStorageService {
  //get token for session
  getToken() {
    let token = JSON.parse(localStorage.getItem("token"));
    return token ? token : null;
  }

  //active session
  setToken(token, user) {
    localStorage.setItem("token", JSON.stringify(token));
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  //Delete token for close session
  deleteToken() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }

  getId() {
    var user = JSON.parse(localStorage.getItem("user"));
    var id = user._id;
    return id ? id : null;
  }

  getUser() {
    let user = JSON.parse(localStorage.getItem("user"));
    return user ? user : null;
  }

  setItem(option, value ){
    localStorage.setItem(option, value);
  }
}
const instance = new LocalStorageService();
Object.freeze(instance);

export default instance;
