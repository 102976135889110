import HTTP from "./http.service";
import ROUTES_API from "const/routes-api.const";

class UserService {
  async list() {
    return await HTTP.get(ROUTES_API.USERS.LIST);
  }

  async detail(user) {
    return await HTTP.get(ROUTES_API.USERS.DETAIL.replace(':id', user));
  }

  async save(params) {
    return await HTTP.post(ROUTES_API.USERS.ADD, params);
  }

  async update(params) {
    return await HTTP.put(ROUTES_API.USERS.UPDATE, params);
  }

  async delete(user) {
    return await HTTP.delete(ROUTES_API.USERS.DELETE.replace(':id', user));
  }
}

const instance = new UserService();
Object.freeze(instance);

export default instance;