import React, { useState } from 'react'
import FILE_VIDEO_ICON from './video-file.png'
import { FileUploader } from 'react-drag-drop-files'

export const FormFileVideo = ({ videoFile, onChange }) => {
  const fileTypes = ["MP4"]
  
  const handleChange = (file) => {
    onChange(file);
  }

  return (
    <div>
      <FileUploader
        handleChange={handleChange}
        name="file"
        fileOrFiles={videoFile}
        types={fileTypes}
        classes="dropzone-wrap"
        minSize={10}
        maxSize={8*1024}
        label="Selecciona un archivo para cargar o arrástralo y suéltalo aquí"
      >
        <div className="dropzone-area-content">
          <img src={FILE_VIDEO_ICON} />
          <h4>Selecciona un archivo para cargar</h4>
          <p>o arrástralo y suéltalo aquí</p>
        </div>
      </FileUploader>
      
    </div>
  )
}
