import Header from 'components/Headers/Header'
import React, { useEffect, useState } from 'react'
import { Button, Media } from 'reactstrap/es'
import { NotesService } from 'services'
import NotesModal from './NotesModal'
import Swal from 'sweetalert2'

const NotesList = () => {
  const [notes, setNotes] = useState([])
  const [editNote, setEditNote] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    loadNotes()
  }, [])
  
  const loadNotes = async() => {
    try {
      setIsLoading(true)
      const res = await NotesService.list()
      let data = res.data
      data.sort((a, b) => {
        const strA = a.title.toUpperCase()
        const strB = b.title.toUpperCase()
        if (strA < strB) {
          return -1
        } else if (strA > strB) {
          return 1
        }

        return 0
      })
      setIsLoading(false)
      setNotes(data)
    } catch (error) {
      console.log(error)
    }
  }

  const addModalHandler = () => {
    setEditNote(null)
    setIsModalOpen(true)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const addHandler = () => {
    setIsModalOpen(false)
    loadNotes()
  }

  const deleteConfirmHandler = (noteID) => {
    Swal.fire({
      title: '¿Quieres eliminar la nota?',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then(result => {
      if (result.isConfirmed) {
        deleteHandler(noteID)
      }
    })
  }

  const deleteHandler = async (noteID) => {
    try {
      await NotesService.delete(noteID)
      Swal.fire('Nota eliminada', '', 'success')
      loadNotes()
    } catch (error) {
      console.log(error)
      Swal.fire('Error', 'Ocurrio un error al eliminar la nota', 'error')
    }
  }

  const editNoteHandler = (note) => {
    setEditNote(note)
    setIsModalOpen(true)
  }

  return (
    <>
      <Header title="Notas" isLoading={isLoading} />
      <div className="container-fluid mt--7">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Listado de notas periodísticas</h3>
                    </div>
                    <div className="col text-right">
                      <button 
                        className="btn btn-primary btn-sm"
                        onClick={addModalHandler}
                      >
                        Nueva
                      </button>
                    </div>
                </div>
              </div>
              <table className="table align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Título</th>
                    <th scope="col">Link</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {
                    notes.map(item => {
                      return (
                        <tr key={item._id}>
                          <td>
                            <Media className="align-items-center">
                              <img src={item.url_photo} alt="" style={{ width: '80px' }} />
                              <Media>
                                <span className="ml-3">{ item.title }</span>
                              </Media>
                            </Media>
                          </td>
                          <td>
                            <a href={item.url} target="blank" >
                              { `${item.url.substring(0,20)}...` }
                            </a>
                          </td>
                          <td className="text-right">
                            <Button
                              color="info"
                              size="sm"
                              title="Editar"
                              onClick={() => editNoteHandler(item)}
                            >
                              <i className="far fa-edit"></i>
                            </Button>
                            <Button
                              color="danger"
                              size="sm"
                              title="Eliminar"
                              onClick={() => deleteConfirmHandler(item._id)}
                            >
                              <i className="fas fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <NotesModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onAdd={addHandler}
        note={editNote}
      />
    </>
  )
}

export default NotesList