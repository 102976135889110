import Input from 'components/input/input'
import React, { useEffect, useState } from 'react'
import {Modal, ModalBody, ModalHeader } from 'reactstrap'
import Switch from "react-switch";
import { SubscriberService } from 'services';
import Swal from 'sweetalert2';


const initialEditSubscriber = {
  name: '',
  email: '',
  is_active: true
}

const EditSubscriberModal = ({ isOpen, onClose, onEdit, editSubscriber }) => {
  const [cSubscriber, setSubscriber] = useState(initialEditSubscriber)
  const [errors, setErrors] = useState({})
  // const { register, handleSubmit, formState: { errors, dirtyFields }, reset } = useForm()
  // const [hasErrors, setHasErrors] = useState(false)

  useEffect(() => {
    let currentSubscriber = editSubscriber ? { ...editSubscriber } : initialEditSubscriber
    setSubscriber(currentSubscriber)
  }, [editSubscriber])
  

  const submitHandler = () => {
    let hasErrors = false;
    let msgErrors = {};
    if (!cSubscriber.name.trim()) {
      hasErrors = true;
      msgErrors.name = 'Campo requerido'
    }

    const validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    if (!cSubscriber.email.trim() && !validEmail.test(cSubscriber.email)) {
      hasErrors = true;
      msgErrors.email = 'Ingrese un correo válido'
    }

    if (!hasErrors) {
      setErrors({})
      saveHandler()
    } else {
      setErrors(msgErrors)
    }
  }

  const saveHandler = async() => {
    try {
      await SubscriberService.update(cSubscriber);
      setSubscriber(initialEditSubscriber)
      Swal.fire({
        title: 'Información actualizada',
        text: 'La información ha sido actualizada',
        icon: 'success',
      })
      onEdit()
    } catch (error) {
      console.log(error)
      Swal.fire({
        title: 'Error',
        text: 'Error al actualizar información',
        icon: 'error',
      })
    }
  }

  const activeToggle = () => {
    setSubscriber({
      ...cSubscriber,
      is_active: !cSubscriber.is_active
    })
  }

  const changeHandle = (e) => {
    setSubscriber({
      ...cSubscriber,
      [e.target.name]: e.target.value
    })
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader>Editar suscriptor</ModalHeader>
        <ModalBody>
          <form>
            <Input
              label="Nombre"
              name='name'
              value={cSubscriber.name}
              onChange={changeHandle}
              errorMessage={errors.name ? errors.name : ''}
            />
            <Input
              label="Correo"
              name='email'
              value={cSubscriber.email}
              onChange={changeHandle}
              errorMessage={errors.email ? errors.email : ''}
            />
            <div className="d-form-switch">
              <label>
                <span>Cuenta activa</span>
                <Switch onChange={activeToggle} checked={cSubscriber.is_active} />
              </label>
            </div>
            
            <div className="mt-4 text-right">
              <button type="button" className="btn btn-secondary" onClick={onClose}>Cancelar</button> 
              <button type="button" className="btn btn-primary" onClick={submitHandler}>Guardar</button> 
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default EditSubscriberModal