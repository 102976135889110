import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import './modal-upload-video.scss'
import { FormFileVideo } from './FormFileVideo'
import { ProgressFileVideo } from './ProgressFileVideo'
import { CardVideoInfo } from './CardVideoInfo'
import { humanFileSize } from 'utilities/helper'
import { Uploader } from 'services/upload'
import { v4 as uuidv4 } from 'uuid'

// {file ? `File name: ${file[0].name}` : "no files uploaded yet"}

const ModalUploadVideo = ({isOpen, videoNum, onClose, onComplete}) => {
  const [videoFile, setVideoFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [uploader, setUploader] = useState(undefined)
  const [uploadPercentage, setUploadPercentage] = useState(0)

  const resetForm = () => {
    setVideoFile(null)
    setIsUploading(false)
    setUploadPercentage(0)
    setUploader(undefined)
  }

  const handleChangeFile = (file) => {
    //console.log(file)
    setVideoFile(file)
  }

  const handleDeleteFile = () => {
    setVideoFile(null)
  }

  const handleUploadFile = () => {
    let percentage = undefined
    const videoUploaderOptions = {
      fileName: `${uuidv4()}`,
      file: videoFile,
      chunkSize: 1024 * 1024 * 10, //50MB
      threadsQuantity: 5
    }

    setIsUploading(true)
    const uploader = new Uploader(videoUploaderOptions)
    setUploader(uploader)

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage
          // console.log(`${percentage}%`)
          setUploadPercentage(percentage)
        }
      })
      .onError((error) => {
        setVideoFile(null)
        console.error(error)
      })
      .onUploadComplete((data) => {
        // console.log('onUploadComplete data:', data);
        uploadCompleteHandler(data.file)
      })

    uploader.start()


  }

  const uploadCompleteHandler = (file) => {
    try {
      onComplete(file)
    } catch (error) {
      console.log(error);
    }
  }

  const handleCancelUpload = () => {
    if (uploader) {
      uploader.abort()
      resetForm()
    } else {
      onClose()
    }
  }

  return (
    <Modal
      isOpen={isOpen} 
      toggle={onClose}
      backdrop={false}
      keyboard={false}
    >
      <ModalHeader close={<button className="close" onClick={onClose}>&times;</button>}>
        {`Video ${videoNum == 1 ? '1 (Histórico)' : '2 (Reflexivo)'}`}
      </ModalHeader>
      <ModalBody>
        { !isUploading &&
          <FormFileVideo 
            videoFile={videoFile}
            onChange={handleChangeFile}
          />
        }
        { isUploading && <ProgressFileVideo percentage={uploadPercentage} text={uploadPercentage > 99 ? 'Guardando video' : 'Subiendo video'} /> }
        { videoFile && 
          <CardVideoInfo 
            videoName={`${videoFile.name}`}
            videoSize={`${humanFileSize(videoFile.size)}`}
            showDelete={!isUploading}
            onDelete={handleDeleteFile}
          />
        }
      </ModalBody>
      <ModalFooter>
        <div className="text-center" style={{width: '100%'}}>
          <Button
            color='secondary'
            onClick={handleCancelUpload}
          >
            { isUploading ? 'Cancelar' : 'Cerrar' }
          </Button>
          { !isUploading && 
            <Button
              color='primary'
              onClick={handleUploadFile}
            >
              Guardar
            </Button>
          }
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ModalUploadVideo