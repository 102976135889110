import Input from 'components/input/input'
import React, { useState } from 'react'
import {Modal, ModalBody, ModalHeader } from 'reactstrap'
import Switch from "react-switch";
import { SubscriberService } from 'services';
import Swal from 'sweetalert2';


const initialSuscriptor = {
  name: '',
  username: '',
  email: '',
  password: '',
  is_active: true
}

const AddSuscriptorModal = ({ isOpen, onClose, onSave }) => {
  const [suscriptor, setSuscriptor] = useState(initialSuscriptor)
  const [errors, setErrors] = useState({})
  // const { register, handleSubmit, formState: { errors, dirtyFields }, reset } = useForm()
  // const [hasErrors, setHasErrors] = useState(false)

  const submitHandler = () => {
    let hasErrors = false;
    let msgErrors = {};
    if (!suscriptor.name.trim()) {
      hasErrors = true;
      msgErrors.name = 'Campo requerido'
    }

    if (!suscriptor.username.trim()) {
      hasErrors = true;
      msgErrors.username = 'Campo requerido'
    }

    const validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    if (!suscriptor.email.trim() && !validEmail.test(suscriptor.email)) {
      hasErrors = true;
      msgErrors.email = 'Ingrese un correo válido'
    }

    if (!suscriptor.password || suscriptor.password.length < 6) {
      hasErrors = true;
      msgErrors.password = 'Ingrese una contraseña valida'
    }

    if (!hasErrors) {
      setErrors({})
      saveHandler()
    } else {
      setErrors(msgErrors)
    }
  }

  const saveHandler = async() => {
    try {
      await SubscriberService.save(suscriptor);
      setSuscriptor(initialSuscriptor)
      Swal.fire({
        title: 'Información actualizada',
        text: 'La información del usuario ha sido actualizada',
        icon: 'success',
      })
      onSave()
    } catch (error) {
      console.log(error.response)
      let errorMessage = 'Error al actualizar información'
      if (error.response?.data?.message) {
        errorMessage = error.response?.data?.message
      }
      Swal.fire({
        title: 'Error',
        text: errorMessage,
        icon: 'error',
      })
    }
  }

  const activeToggle = () => {
    setSuscriptor({
      ...suscriptor,
      is_active: !suscriptor.is_active
    })
  }

  const changeHandle = (e) => {
    setSuscriptor({
      ...suscriptor,
      [e.target.name]: e.target.value
    })
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader>Nuevo suscriptor</ModalHeader>
        <ModalBody>
          <form>
            <Input
              label="Nombre"
              name='name'
              value={suscriptor.name}
              onChange={changeHandle}
              errorMessage={errors.name ? errors.name : ''}
            />
            <Input
              label="Usuario"
              name='username'
              value={suscriptor.username}
              onChange={changeHandle}
              errorMessage={errors.username ? errors.username : ''}
            />
            <Input
              label="Correo"
              name='email'
              value={suscriptor.email}
              onChange={changeHandle}
              errorMessage={errors.email ? errors.email : ''}
            />
            <Input
              label="Contraseña (Mín. 6 carácteres)"
              name='password'
              value={suscriptor.password}
              onChange={changeHandle}
              errorMessage={errors.password ? errors.password : ''}
            />

            <div className="d-form-switch">
              <label>
                <span>Cuenta activa</span>
                <Switch onChange={activeToggle} checked={suscriptor.is_active} />
              </label>
            </div>
            
            <div className="mt-4 text-right">
              <button type="button" className="btn btn-secondary" onClick={onClose}>Cancelar</button> 
              <button type="button" className="btn btn-primary" onClick={submitHandler}>Guardar</button> 
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default AddSuscriptorModal