let _END_POINT_ = 'http://localhost:8080/';
//let _END_POINT_ = 'https://api.murmullosdelsilencio.com/';
let env = process.env.REACT_APP_STAGE;

switch (env) {
    case 'prod':
        _END_POINT_ = 'https://api.murmullosdelsilencio.com/';
        break;
    case 'dev':
      _END_POINT_ = 'https://api-dev.murmullosdelsilencio.com/';
      break;
    default:
        break;
}

module.exports = {
  api: {
    API_URL: _END_POINT_,
  }
}