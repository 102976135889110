import axios from 'axios';
import LocalStorageService from './localStorage.service';
 
const HTTP = axios.create();
    // baseURL: END_POINT,
    // timeout: 5000

HTTP.interceptors.request.use(
    (config) => {
        const token = LocalStorageService.getToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
            // config.headers['token-api'] = `${currentUser}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

HTTP.interceptors.response.use(
    response => response,
    error => {
        const { status } = error.response;
        if (status === 401) {
            LocalStorageService.deleteToken();
            window.location.replace('');
        }
        return Promise.reject(error);
    }
);

export default HTTP;

