import React, { useEffect, useState } from 'react'
import Switch from "react-switch";
import Header from 'components/Headers/Header'
import Input from 'components/input/input'
import Select from 'react-select'
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from 'sweetalert2';

import IMG_AVATAR from '../../assets/img/1280x720.svg';
import { InterviewService, CategoryService } from 'services';
import ModalUploading from './ModalUploading';
import { useHistory } from 'react-router-dom';
import { Uploader } from 'services/upload';

const toolbarOptions = {
  options: ['inline', 'link']
}

const interviewInit = {
  name: '',
  picture: '',
  external_video: '',
  related_interviews: [],
  resume: '',
  category: '',
  date_place_birth: '',
  degree: '',
  nationality: '',
  religion: '',
  father: '',
  father_birth: '',
  mother: '',
  mother_birth: '',
  siblings: '',
  is_active: false
}

const AddInterview = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [interview, setInterview] = useState(interviewInit)
  const [categorySelected, setCategorySelected] = useState(null)
  const [avatarPreview, setAvatarPreview] = useState(IMG_AVATAR)
  const [avatarFile, setAvatarFile] = useState(null)
  const [errors, setErrors] = useState({})
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [isUploading, setIsUploading] = useState(false)
  const [uploadingText, setUploadingText] = useState('')
  const [optionsRelated, setOptionsRelated] = useState([])
  const [selectInterviews, setSelectInterviews] = useState([])
  const [uploader, setUploader] = useState(undefined)

  useEffect(() => {
    loadCategories()
    loadRelatedInterviews()
  }, [])

  const loadCategories = async() => {
    try {
      const response = await CategoryService.list();
      setCategories(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const loadRelatedInterviews = async () => {
    try {
      let options = []
      const response = await InterviewService.list()
      for (let i = 0; i < response.data.length; i++) {
        const element = response.data[i];
        options.push({
          value: element._id,
          label: element.name
        })
      }

      setOptionsRelated(options)
    } catch (error) {
      console.log(error)
    }
  }

  const onChangeInputHandler = (e) => {
    let inputVal = e.target.value
    let inputName = e.target.name

    setInterview({
      ...interview,
      [`${inputName}`]: inputVal
    })
  }
  
  const onEditorStateChangeHandle = (eState) => {
    setEditorState(eState)
  }

  const onActiveToggle = () => {
    setInterview({
      ...interview,
      is_active: !interview.is_active
    })
  }

  const onCloseUploading = () => {
    setIsUploading(false)
  }

  const categoryChangeHandler = (e) => {
    console.log('categoryChangeHandler', e.target.value)
    let selected = categories.find(item => {
      return item._id === e.target.value 
    })

    console.log('selectedCategory::', selected)
    setCategorySelected(selected)
  }

  const selectFileHanlder = () => {
    document.getElementById('photoInterview').click();
  }

  const changePhotoFileHandler = (e) => {
    e.preventDefault();
    let file = e.target.files[0];

    if (file) {
      let preview = URL.createObjectURL(file);

      setAvatarFile(file)
      setAvatarPreview(preview)
      // if (this.props.onChangeAvatar) {
      //   this.props.onChangeAvatar(file);
      // }
    }
  }

  const onSubmitHandler = () => {
    let isValidForm = true;
    let formErrors = {};
    if (!interview.name) {
      isValidForm = false;
      formErrors.name = "El campo es obligatorio"
    }

    if (!categorySelected) {
      isValidForm = false;
      formErrors.category = "Debe seleccionar una categoría"
    }

    if (isValidForm) {
      setErrors({})
      onSaveHandler()
    } else {
      setErrors(formErrors)
    }
  }

  const onSaveHandler = async () => {
    let paramsSave = { ...interview }
    if (categorySelected.key.toLowerCase() === 'especialistas') {
      paramsSave.date_place_birth = ''
      paramsSave.degree = ''
      paramsSave.nationality = ''
      paramsSave.religion = ''
      //resum html
      paramsSave.resume = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    } else {
      paramsSave.resume = ''
    }

    paramsSave.category = categorySelected._id

    if (selectInterviews && selectInterviews.length) {
      for (let i = 0; i < selectInterviews.length; i++) {
        paramsSave.related_interviews.push(selectInterviews[i].value)
      }
    }

    try {
      setUploadingText('Guardando información...')
      setIsUploading(true)
      const response = await InterviewService.save(paramsSave);
      console.log(response.data)
      if (response.data._id) {
        //Guarda imagen
        if (avatarFile) {
          setUploadingText('Guardando imagen...')
          await onSaveImage(response.data._id)
        }

        // let fileTitle1 = categorySelected.key.toLowerCase() === 'especialistas' ? 'Video 1': 'Video Histórico';
        // const fileVideo1 = document.getElementById('interviewVideo1')
        // if (fileVideo1.files && fileVideo1.files[0]) {
        //   setUploadingText(`Guardando ${fileTitle1}...`)
        //   await onSaveVideo(response.data._id, fileTitle1, fileVideo1.files[0])
        // }

        // let fileTitle2 = categorySelected.key.toLowerCase() === 'especialistas' ? 'Video 2': 'Video Reflexivo';
        // const fileVideo2 = document.getElementById('interviewVideo2')
        // if (fileVideo2.files && fileVideo2.files[0]) {
        //   setUploadingText(`Guardando ${fileTitle1}...`)
        //   await onSaveVideo(response.data._id, fileTitle2, fileVideo2.files[0])
        // }
      }
      setIsUploading(false)
      await Swal.fire({
        title: 'Información Guardada',
        text: 'La entrevista ha sido guardada',
        icon: 'success',
      })

      if (response.data._id) {
        history.push(`/admin/edit-interview/${response.data._id}`)
      } else {
        history.push('/admin/entrevistas')
      }
    } catch (error) {
      console.log(error)
      setIsUploading(false)
      Swal.fire({
        title: 'Error',
        text: 'Ocurrio un error al guardar la información',
        icon: 'error',
      })
    }
  }

  const onChangeRelatedHandler = (newValue, actionMeta) => {
    setSelectInterviews(newValue)
  };

  const onSaveImage = async (interviewID) => {
    try {
      if (avatarFile) {
        const formData = new FormData();
        formData.append('photo', avatarFile);

        const response = await InterviewService.upload_image(interviewID, formData);
        console.log('uploadImage: ', response.data)
        return response.data.status
      }
    } catch (e) {
      console.log(e);
      return false
      // Swal.fire({
      //   title: 'Error',
      //   text: 'Ocurrio un error al intentar guardar la imagen',
      //   icon: 'error',
      // })
    }
  }

  const removeFilePhoto = () => {
    setAvatarFile(null)
    setAvatarPreview(IMG_AVATAR)
  }

  const onSaveVideo = async (interviewID, titleFile, videoFile) => {
    //TODO: guarda video
    try {
      const videoUploaderOptions = {
        filename: '',
        file: videoFile
      }

      let percentage = undefined

      const uploader = new Uploader(videoUploaderOptions)
      setUploader(uploader)

      uploader
        .onProgress(({ percentage: newPercentage }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage
            console.log(`${percentage}%`)
          } else {
            
          }
        })
        .onError((error) => {
          console.error(error)
        })

      uploader.start()
      // const formData = new FormData()
      // formData.append('file', videoFile)
      // formData.append('interview', interviewID)
      // formData.append('title', titleFile)
      // const response = await VideoService.save(formData)
      // console.log('uploadVideo: ', response.data)
      // return response.data
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Header title="Entrevistas" isLoading={isLoading} />
      <div className="container-fluid mt--7">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Nueva entrevista</h3>
                    </div>
                </div>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="interview-photo mb-4">
                        <input 
                          type="file" 
                          style={{display: 'none'}}
                          id="photoInterview" 
                          accept="image/jpeg, image/png, image/jpg" 
                          onChange={ changePhotoFileHandler } 
                        />
                        <div className="interview-preview-img">
                          <img src={avatarPreview} alt='preview' />
                        </div>
                        <div className="text-center">
                          <button 
                            type="button" 
                            className="btn btn-sm btn-info"
                            onClick={selectFileHanlder}
                          >
                            Seleecionar imagen
                          </button>
                          { avatarFile &&
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary"
                              onClick={removeFilePhoto}
                            >
                              Eliminar
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="d-form-switch">
                        <label>
                          <span>Publicada</span>
                          <Switch onChange={onActiveToggle} checked={interview.is_active} />
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <Input 
                            label="Nombre*"
                            name="name"
                            onChange={onChangeInputHandler}
                            errorMessage={errors.name || ''}
                          />
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-control-component">
                            <label>Categoría*</label>
                            <select 
                              className="form-control" 
                              name="category"
                              value={categorySelected?._id || ''}
                              onChange={categoryChangeHandler}
                            >
                              <option>Selecciona una categoría</option>
                              { categories.map(item => {
                                return <option value={item._id} key={`cat-${item._id}`}>{item.title}</option>
                              }) }
                            </select>
                            { errors.category && ( <div className="form-error text-danger">{ errors.category }</div> ) }
                          </div>
                        </div>
                      </div>
                    </div>  
                  </div>
                  { categorySelected && categorySelected.key.toLowerCase() === 'especialistas' 
                    ? <div className="row mt-4">
                        <div className="col">
                          <label><strong>Resumen</strong></label>
                          <Editor 
                            editorState={editorState}
                            toolbar={toolbarOptions}
                            wrapperClassName="wrap-editor"
                            onEditorStateChange={onEditorStateChangeHandle}
                          />
                        </div>
                      </div>
                    : <div className="mt-4">
                        <div className="row">
                          <div className="col-md-6">
                            <Input 
                              label="Lugar y fecha de nacimiento"
                              name="date_place_birth"
                              onChange={onChangeInputHandler}
                            />
                          </div>
                          <div className="col-md-6">
                            <Input 
                              label="Nacionalidad"
                              name="nationality"
                              onChange={onChangeInputHandler}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Input 
                              label="Estudios"
                              name="degree"
                              onChange={onChangeInputHandler}
                            />
                          </div>
                          <div className="col-md-6">
                            <Input 
                              label="Religion"
                              name="religion"
                              onChange={onChangeInputHandler}
                            />
                          </div>
                        </div>
                      </div>
                  }
                  <hr/>
                  <h3>Información del padre</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <Input 
                        label="Nombre"
                        name="father"
                        onChange={onChangeInputHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input 
                        label="Lugar y fecha de nacimiento"
                        name="father_birth"
                        onChange={onChangeInputHandler}
                      />
                    </div>
                  </div>
                  <hr/>
                  <h3>Información de la madre</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <Input 
                        label="Nombre"
                        name="mother"
                        onChange={onChangeInputHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input 
                        label="Lugar y fecha de nacimiento"
                        name="mother_birth"
                        onChange={onChangeInputHandler}
                      />
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-md-12">
                      <Input 
                        label="Hermanos"
                        name="siblings"
                        onChange={onChangeInputHandler}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="form-group form-control-component">
                        <label>Entrevistas asociadas</label>
                        <Select 
                          value={selectInterviews}
                          name="associated"
                          isMulti
                          options={optionsRelated}
                          onChange={onChangeRelatedHandler}
                        />
                      </div>
                    </div>
                  </div>
                  
                  <hr/>
                  {/* <h3 className="mb-3">Videos</h3>
                  <div className="row">
                    <div className="col-md-6">
                    <h3>{ categorySelected && categorySelected.key.toLowerCase() === 'especialistas' ? 'Video 1' : 'Video Histórico'}</h3>
                      <div className="form-group">
                        <label>Archivo (.mp4)</label>
                        <input 
                          type="file" 
                          id="interviewVideo1" 
                          className="form-control"
                          accept="video/mp4" 
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h3>{ categorySelected && categorySelected.key.toLowerCase() === 'especialistas' ? 'Video 2' : 'Video Reflexivo'}</h3>
                      <div className="form-group">
                        <label>Archivo (.mp4)</label>
                        <input 
                          type="file" 
                          id="interviewVideo2" 
                          className="form-control"
                          accept="video/mp4" 
                        />
                      </div>
                    </div>
                  </div> */}

                  <div className='form-actions text-right mt-5'>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => history.push('/admin/entrevistas')}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={onSubmitHandler}
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalUploading 
        isOpen={isUploading}
        onClose={onCloseUploading}
        textMessage={uploadingText}
      />
    </>
  )
}

export default AddInterview