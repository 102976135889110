import axios from "axios";
import ROUTES_API from "../const/routes-api.const";
import HTTP from "./http.service";
import LocalStorage from "./localStorage.service";

class AuthService {
  //Login
  async login(params) {
    var data = {
      username: params.username,
      password: params.password,
    };
    return HTTP.post(ROUTES_API.AUTH.LOGIN, data);
  }

  //Logout
  async logout() {
    return HTTP.post(ROUTES_API.AUTH.LOGOUT);
  }

  //Validate code for enter to session
  async validateCode(params) {
    var data = {
      code: params.code,
      token: LocalStorage.getToken(),
    };
    return HTTP.post(ROUTES_API.AUTH.VALIDATE_CODE, data);
  }

  //Send email to resent password
  async forgotPassword(email) {
    var data = {
      email: email,
    };
    return HTTP.post(ROUTES_API.AUTH.FORGOT_PASSWORD, data);
  }

  //Validate session for enter
  async validateSession(params) {
    var data = {
      token: params,
    };
    return HTTP.post(ROUTES_API.AUTH.VALIDATE_PASSWORD, data);
  }

  //Change password
  async changePassword(params) {
    var data = {
      token: params.token,
      password: params.password,
    };
    return HTTP.post(ROUTES_API.AUTH.CHANGE_PASSWORD, data);
  }

  //Resend password when forget pass
  resendPassword(params) {
    return axios.post(ROUTES_API.AUTH.RESEND_PASSWORD, { params });
  }
}

const instance = new AuthService();
Object.freeze(instance);

export default instance;
