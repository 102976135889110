import Login from "views/examples/Login.js";
import InterviewsList from "views/Interviews/InterviewsList";
import NotesList from "views/Notes/NotesList";
import GalleryList from "views/Gallery/GalleryList";
import SuscriptoresList from "views/Suscriptores/SuscriptoresList";
import CategoriesList from "views/Categories/CategoriesList";
import AddInterview from "views/Interviews/AddInterview";
import EditInterview from "views/Interviews/EditInterview";

var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin",
  // },
  {
    path: "/entrevistas",
    name: "Entrevistas",
    icon: "ni ni-bullet-list-67 text-primary",
    component: InterviewsList,
    layout: "/admin",
  },
  {
    path: "/categorias",
    name: "Categorias",
    icon: "ni ni-bullet-list-67 text-primary",
    component: CategoriesList,
    layout: "/admin",
  },
  {
    path: "/notas",
    name: "Notas",
    icon: "ni ni-bullet-list-67 text-primary",
    component: NotesList,
    layout: "/admin",
  },
  {
      path: "/add-interview",
      name: "Entrevistas",
      icon: "ni ni-bullet-list-67 text-primary",
      component: AddInterview,
      layout: "/admin",
      navHidden: true
    },
    {
      path: "/edit-interview/:id",
      name: "Entrevistas",
      icon: "ni ni-bullet-list-67 text-primary",
      component: EditInterview,
      layout: "/admin",
      navHidden: true
    },
  {
    path: "/galeria",
    name: "Galeria",
    icon: "ni ni-bullet-list-67 text-primary",
    component: GalleryList,
    layout: "/admin",
  },
  {
    path: "/suscriptores",
    name: "Suscriptores",
    icon: "ni ni-bullet-list-67 text-primary",
    component: SuscriptoresList,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
];
export default routes;
