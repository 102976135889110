import HTTP from "./http.service";
import ROUTES_API from "const/routes-api.const";

class CategoryService {
  async list() {
    return await HTTP.get(ROUTES_API.CATEGORIES.LIST);
  }

  async detail(categoryId) {
    return await HTTP.get(ROUTES_API.CATEGORIES.DETAIL.replace(':id', categoryId));
  }

  async save(params) {
    return await HTTP.post(ROUTES_API.CATEGORIES.ADD, params);
  }

  async update(params) {
    return await HTTP.put(ROUTES_API.CATEGORIES.UPDATE, params);
  }

  async delete(categoryId) {
    return await HTTP.delete(ROUTES_API.CATEGORIES.DELETE.replace(':id', categoryId));
  }
}

const instance = new CategoryService();
Object.freeze(instance);

export default instance;