import { React, useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import "./input.scss";

export default function Input(props) {
  const { type, label, className, disabled, errorMessage, onChange, ...restProps } = props;
  const [typeInput, setTypeInput] = useState("");

  useEffect(() => {
    let defaultType = props.type ? props.type : 'text'
    setTypeInput(defaultType);
  }, []);

  const changeType = () => {
    if (typeInput === "text") {
      setTypeInput("password");
    } else {
      setTypeInput("text");
    }
  };

  const handleChange = (event) => {
    if (typeof props.onChange === 'function') {
      onChange(event)
    }
  };

  const classNameContent = className ? `inp-wrap ${className}` : `inp-wrap`;
  
  return (
    <div className={"form-group form-control-component"}>
      <label className="label">{label}</label>
      <div className={classNameContent}>
        <input 
        className="form-control"
          type={typeInput || "text"} 
          disabled={disabled}
          autoComplete="off" 
          onChange={ handleChange }
          maxLength={type === "password" ? "40" : null}
          { ...restProps }
        />
        {type === "password" ? (
          <span className="input-icon">
            <Icon.Eye className="iconDashboard" onClick={changeType} />
          </span>
        ) : null}
      </div>
      { errorMessage && ( <div className="form-error text-danger">{ errorMessage }</div> ) }
    </div>
  );
}
