import { api } from "../config";

const API_URL = `${api.API_URL}backoffice/`;
const AUTH = 'auth';
const CATEGORIES = 'categories';
const USERS = 'users';
const INTERVIEWS = 'interviews';
const VIDEOS = 'videos';
const GALLERY = 'gallery';
const SUBSCRIBERS = 'subscribers';
const NOTES = 'notes';

const ROUTES_API = {
  AUTH: {
    LOGIN: `${API_URL}${AUTH}/login`,
    FORGOT_PASSWORD: `${API_URL}${AUTH}/reset-password-request`,
    LOGOUT: `${API_URL}logout`
  },
  CATEGORIES: {
    LIST: `${API_URL}${CATEGORIES}`,
    DETAIL: `${API_URL}${CATEGORIES}/:id`,
    ADD: `${API_URL}${CATEGORIES}`,
    UPDATE: `${API_URL}${CATEGORIES}`,
    DELETE: `${API_URL}${CATEGORIES}/:id`,
  },
  USERS: {
    LIST: `${API_URL}${USERS}`,
    DETAIL: `${API_URL}${USERS}/:id`,
    ADD: `${API_URL}${USERS}/create`,
    UPDATE: `${API_URL}${USERS}/update`,
    DELETE: `${API_URL}${USERS}/:id`,
  },
  INTERVIEWS: {
    LIST: `${API_URL}${INTERVIEWS}`,
    DETAIL: `${API_URL}${INTERVIEWS}/detail/:id`,
    ADD: `${API_URL}${INTERVIEWS}`,
    UPDATE: `${API_URL}${INTERVIEWS}`,
    UPLOAD_PHOTO: `${API_URL}${INTERVIEWS}/photo/:id`,
    DELETE: `${API_URL}${INTERVIEWS}/:id`,
  },
  VIDEOS: {
    LIST: `${API_URL}${VIDEOS}`,
    DETAIL: `${API_URL}${VIDEOS}/:id`,
    ADD: `${API_URL}${VIDEOS}/file`,
    SAVE_INFO: `${API_URL}${VIDEOS}`,
    UPDATE_INFO: `${API_URL}${VIDEOS}`,
    UPLOAD_INIT: `${API_URL}${VIDEOS}/upload/initializeMultipartUpload`,
    UPLOAD_GET_PRESIGNED: `${API_URL}${VIDEOS}/upload/getMultipartPreSignedUrls`,
    UPLOAD_FINALIZE: `${API_URL}${VIDEOS}/upload/finalizeMultipartUpload`,
  },
  GALLERY: {
    LIST: `${API_URL}${GALLERY}`,
    DETAIL: `${API_URL}${GALLERY}/:id`,
    UPLOAD_IMG: `${API_URL}${GALLERY}/photo`,
    DELETE: `${API_URL}${GALLERY}/:id`,
  },
  SUBSCRIBERS: {
    LIST: `${API_URL}${SUBSCRIBERS}`,
    DETAIL: `${API_URL}${SUBSCRIBERS}/:id`,
    ADD: `${API_URL}${SUBSCRIBERS}`,
    EDIT: `${API_URL}${SUBSCRIBERS}`,
    CHANGE_PWD: `${API_URL}${SUBSCRIBERS}/change-password`,
    DELETE: `${API_URL}${SUBSCRIBERS}/:id`,
  },
  NOTES: {
    LIST: `${API_URL}${NOTES}`,
    DETAIL: `${API_URL}${NOTES}/:id`,
    UPDATE: `${API_URL}${NOTES}/:id`,
    UPDATE_PHOTO: `${API_URL}${NOTES}/photo`,
    ADD: `${API_URL}${NOTES}/photo`,
    DELETE: `${API_URL}${NOTES}/:id`,
  },
};

export default ROUTES_API;

