import React, { useEffect, useState } from 'react'
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap/es'
import Swal from 'sweetalert2';
import Header from 'components/Headers/Header'
import CategoryService from '../../services/category.service';


const CategoriesList = () => {
  const [categories, setCategories] = useState([])
  const [shoModalForm, setShoModalForm] = useState(false)
  const [category, setCategory] = useState({ title: '' })
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadCategories()
  }, [])
  
  const toggleModal = () => setShoModalForm(!shoModalForm)

  const loadCategories = async () => {
    try {
      setIsLoading(true);
      const result = await CategoryService.list();
      setIsLoading(false);
      setCategories(result.data)
    } catch (error) {
      console.log(error)
      setIsLoading(false);
    }
  }

  const openEditForm = (editCategory) => {
    setCategory(editCategory)
    toggleModal()
  }

  const openAddForm = () => {
    setCategory({ title: '' })
    toggleModal()
  }

  const changeHandle = (e) => {
    setCategory({
      ...category,
      [e.target.name]: e.target.value
    })
  }

  const saveCategoryHandle = async () => {
    if (!category.title) {
      return;
    }

    let categorySaved = { ...category };
    categorySaved.key = createSlugCategory(category.title);
    
    try {
      if (categorySaved._id) {
        const res = await CategoryService.update(categorySaved)
        console.log('update: ', res)
      } else {
        const res = await CategoryService.save(categorySaved);
        console.log('save: ', res)
      }

      toggleModal()
      Swal.fire({
        title: 'Categoría Guardada',
        text: 'La información de la categoría ha sido guardada',
        icon: 'success',
      })
      loadCategories()
    } catch (error) {
      console.log(error);
      toggleModal()
      Swal.fire({
        title: 'Error!',
        text: 'Ocurrio un error al intentar guardar la información',
        icon: 'error',
      })
    }
  }

  const createSlugCategory = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeeiiiiooooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
  
    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
             .replace(/\s+/g, '-') // collapse whitespace and replace by -
             .replace(/-+/g, '-'); // collapse dashes
  
    return str;
  };

  const deleteConfirmHandler = (categoryID) => {
    Swal.fire({
      title: '¿Quieres eliminar la categoría?',
      text: 'Al eliminar la categoría podría afectar la visualización de las notas asociadas',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then(result => {
      if (result.isConfirmed) {
        deleteHandler(categoryID)
      }
    })
  }

  const deleteHandler = async (categoryID) => {
    try {
      await CategoryService.delete(categoryID)
      Swal.fire({
        title: 'Categoría eliminada',
        icon: 'success'
      })
      loadCategories()
    } catch (error) {
      console.log(error)
      Swal.fire({
        title: 'Error',
        text: 'Ocurrio un error al intentar eliminar la categoría',
        icon: 'error'
      })
    }
  }

  return (
    <>
    <Header title="Categorías" isLoading={isLoading} />
    <div className="container-fluid mt--7">
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header border-0">
              <div className="row align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Listado de categorías</h3>
                  </div>
                  <div className="col text-right">
                    <button 
                      className="btn btn-primary btn-sm"
                      onClick={openAddForm}
                    >
                      Nueva
                    </button>
                  </div>
              </div>
            </div>
            <table className="table align-items-center table-flush">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                { 
                  categories.map(item => {
                    return (
                      <tr key={`${item._id}`}>
                        <td>{item.title}</td>
                        <td className="text-right">
                          <Button
                            color="info"
                            size="sm"
                            title="Editar"
                            onClick={() => openEditForm(item)}
                          >
                            <i className="far fa-edit"></i>
                          </Button>
                          <Button
                            color="danger"
                            size="sm"
                            title="Eliminar"
                            onClick={() => deleteConfirmHandler(item._id)}
                          >
                            <i className="fas fa-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    )
                  }) 
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <Modal isOpen={shoModalForm} toggle={toggleModal}>
      <ModalHeader>
        { category._id ? `Editar categoría` : `Nueva categoría` }
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="form-group">
            <label>Nombre:</label>
            <Input
              name='title'
              value={category.title}
              onChange={changeHandle}
            />
          </div>
          <div className="mt-4 text-right">
            <button type="button" className="btn btn-secondary" onClick={toggleModal}>Cancelar</button> 
            <button type="button" className="btn btn-primary" onClick={saveCategoryHandle}>Guardar</button> 
          </div>
        </form>
      </ModalBody>
    </Modal>
  </>
  )
}

export default CategoriesList