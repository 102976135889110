import HTTP from "./http.service";
import ROUTES_API from "const/routes-api.const";

class VideoService {
  async list() {
    return await HTTP.get(ROUTES_API.VIDEOS.LIST);
  }

  async detail(video) {
    return await HTTP.get(ROUTES_API.VIDEOS.DETAIL.replace(':id', video));
  }

  async save(params) {
    return await HTTP.post(ROUTES_API.VIDEOS.ADD, params);
  }

  async saveInfo(params) {
    return await HTTP.post(ROUTES_API.VIDEOS.SAVE_INFO, params);
  }

  async updateInfo(params) {
    return await HTTP.put(ROUTES_API.VIDEOS.UPDATE_INFO, params);
  }
}

const instance = new VideoService();
Object.freeze(instance);

export default instance;