import React, { useEffect, useState } from 'react'
import Header from 'components/Headers/Header'
import GalleryModal from './GalleryModal'
import { GalleryService } from 'services'
import Swal from 'sweetalert2';


const GalleryList = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [galleryImages, setGalleryImages] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    loadImagesGallery()
  }, [])

  const loadImagesGallery = async () => {
    try {
      setIsLoading(true)
      const response = await GalleryService.list()
      console.log(response.data)
      setIsLoading(false)
      setGalleryImages(response.data)
    } catch (error) {
      console.log(error)
    }
  }


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const addHandler = () => {
    setIsModalOpen(false)
    loadImagesGallery()
  }

  const deleteConfirmHandler = (itemId) => {
    //confirm delete
    console.log('Delete ID: ', itemId)
    
    Swal.fire({
      show: true,
      title: '¿Deseas borrar la imagen?',
      text: "Al borrar la imagen de la galería esta no podrá ser recuperada",
      icon: 'warning',
      confirmButtonText: 'Borrar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-secondary'
      },
      buttonsStyling: false
    }).then(result => {
      console.log('confirmado borrar', result)
      if(result.isConfirmed) {
        deleteHandler(itemId)
      }
    })

    
  }

  const deleteHandler = async (itemId) => {
    try {
      await GalleryService.delete(itemId)
      Swal.fire({
        show: true,
        title: 'Imagen borrada',
        text: 'La imagen ha sido eliminada de la galería',
        icon: 'success'
      })
      loadImagesGallery();
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Header title="Galería" isLoading={isLoading} />
      <div className="container-fluid mt--7">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Imágenes de la Galería</h3>
                  </div>
                  <div className="col text-right">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={toggleModal}
                    >
                      Nueva
                    </button>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="list-group gallery">
                    { 
                      galleryImages.map(item => {
                        return (
                          <div className="col-md-4 col-sm-6" key={item._id}>
                            <div className="thumbnail text-center" rel="ligthbox">
                              <img className="img-fluid" style={{maxHeight: '180px'}} alt="" src={item.url_photo} />
                              <div className="mt-2 row">
                                <div className="col-8">
                                  <p className="text-muted text-left">{item.title}</p>
                                </div>
                                <div className="col-4 text-right">
                                  <button 
                                    className="btn btn-danger btn-sm"
                                    onClick={() => deleteConfirmHandler(item._id)}
                                  >
                                    Borrar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }) 
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GalleryModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onAdd={addHandler}
      />
    </>
  )
}

export default GalleryList