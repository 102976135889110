import HTTP from "./http.service";
import ROUTES_API from "const/routes-api.const";

class GalleryService {
  async list() {
    return await HTTP.get(ROUTES_API.GALLERY.LIST);
  }

  async detail(photo) {
    return await HTTP.get(ROUTES_API.GALLERY.DETAIL.replace(':id', photo));
  }

  async save(params) {
    return await HTTP.post(ROUTES_API.GALLERY.UPLOAD_IMG, 
      params,
      { 
        headers: {
          'Content-Type': 'multipart/form-data'
        } 
      }
    );
  }

  async delete(photo) {
    return await HTTP.delete(ROUTES_API.GALLERY.DELETE.replace(':id', photo));
  }
}

const instance = new GalleryService();
Object.freeze(instance);

export default instance;