/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { LocalStorageService } from "services";
import { AuthService } from "services";

const Login = () => {
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [errors, setErrors] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    const token = LocalStorageService.getToken()
    const user = LocalStorageService.getUser()

    if (token && user) {
      setIsLoggedIn(true)
    } 
  }, [])
  

  const onChangePassword = (e) => {
    setPassword(e.target.value)
  }

  const onChangeUsername = (e) => {
    setUsername(e.target.value.trim())
  }

  const submitHandler = async () => {
    try {
      console.log('login: ', username, password)
      const response = await AuthService.login({username, password})
      console.log(response)
      if (response.data) {
        LocalStorageService.setToken(response.data.token, response.data.user)
        setErrors('')
        setIsLoggedIn(true)
      } else {
        setErrors('Ocurrion un error. Pongase en contacto con el administrador')
      }
    } catch (error) {
      console.log(error)
      if (error.response?.data) {
        setErrors(error.response.data.message)
      }
    }
  }

  const _renderForm = () => {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Ingrese su usuario y contraseña</small>
              </div>
              {
                errors && (
                  <div className="py-3 mb-4 text-danger">
                    {errors}
                  </div>
                )
              }
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Usuario"
                      type="text"
                      name="username"
                      value={username}
                      onChange={onChangeUsername}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Contraseña"
                      type="password"
                      name="password"
                      value={password}
                      onChange={onChangePassword}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button 
                    className="my-4" 
                    color="primary" 
                    type="button"
                    onClick={submitHandler}
                  >
                    Ingresar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <small>¿Olvidaste tu contraseña?</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    )
  }

  return (
    <>
      { !isLoggedIn ? _renderForm() : <Redirect to="/" /> }
    </>
  );
};

export default Login;
