import Header from 'components/Headers/Header'
import React, { useEffect, useState } from 'react'
import { Button, Badge } from 'reactstrap/es'
import { InterviewService } from 'services'
import DataTable from 'react-data-table-component';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Searchbar from 'components/Searchbar/Searchbar';
import { accentFold } from '../../utilities/helper'

const InterviewsList = () => {
  const [interviews, setInterviews] = useState([])
  const [filterInterviews, setFilterInterviews] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const columns = [
    {
      name: 'Nombre',
      selector: row => row.name,
      sortable: true
    },
    {
      name: 'Categoría',
      selector: row => row.category ? row.category.title : '',
      sortable: true
    },
    {
      name: 'Estatus',
      selector: row => {
        return (
          <Badge color="" className="badge-dot mr-4">
            <i className={`${row.is_active ? 'bg-success' : 'bg-danger'}`} />
            { row.is_active ? `Publicada` : `Borrador` }
          </Badge>
        )
      },
    },
    {
      name: 'Acciones',
      selector: row => {
        return (
          <div>
            <Link to={`/admin/edit-interview/${row._id}`} className="btn btn-sm btn-info">
              <i className="far fa-edit"></i>
            </Link>
            <Button 
              color="danger"
              size="sm"
              title="Eliminar"
              onClick={() => confirmDelete(row)}
            >
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        )
      },
      width: '120px'
    }
  ]

  useEffect(() => {
    loadInterviews()
  }, [])

  const loadInterviews = async() => {
    try {
      setIsLoading(true)
      const response = await InterviewService.list();
      setIsLoading(false)
      setInterviews(response.data)
      setFilterInterviews(response.data)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const searchHandle = (query) => {
    let results = interviews;
    console.log(query);

    if (query) {
      let strSearch = accentFold(query);
      let expSearch = new RegExp(`${strSearch}.*`, 'i');
      results = interviews.filter(item => {
        return expSearch.test(`${accentFold(item.name)}`);
      });
    }


    setFilterInterviews(results)
  }

  const confirmDelete = (item) => {
    Swal.fire({
      title: '¿Esta seguro de eliminar la entrevista "'+item.name+'"?',
      text: "No se podrá revertir esta acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#D6D3D3",
      cancelButtonTextColor: "#333",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteHandler(item)
      }
    });
  }

  const deleteHandler = async (item) => {
    try {
      await InterviewService.delete(item._id)
      Swal.fire({
        title: "Cuenta eliminada",
        text: 'La entrevista "' + item.name + '" ha sido eliminada del sitio',
        icon: "success"
      })
      loadInterviews()
    } catch(e) {
      Swal.fire({
        title: "Error al eliminar la entrevista",
        text: 'Ocurrio un error al intentar eliminar la entrevista.',
        icon: "error"
      });
    }
  }
  

  return (
    <>
      <Header title="Entrevistas" isLoading={isLoading} />
      <div className="container-fluid mt--7">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Listado de entrevistas</h3>
                  </div>
                  <div className="col text-right">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => history.push('/admin/add-interview')}
                    >
                      Nueva
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body px-0">
                <div className="row justify-content-end px-4">
                  <div className="col-4 mb-3">
                    <Searchbar 
                      showCancelSearch={true}
                      onChange={searchHandle}
                    />
                  </div>
                </div>
                <DataTable 
                  columns={columns}
                  data={filterInterviews}
                  pagination
                  keyField='_id'
                  striped
                  defaultSortFieldId={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InterviewsList