/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { Container } from "reactstrap";
import { Spinner } from "reactstrap/es";

const Header = (props) => {
  return (
    <>
      <div className="header bg-gradient-info pb-7 pt-5 pt-md-6">
        <Container fluid>
          <div className="header-body">
            <div className="align-items-center justify-content-between py-4 row">
              <div className="col-7 col-lg-6">
                <h6 className="h2 text-white d-inline-block">{ `${props.title || ''}` }</h6>
              </div>
              { props.isLoading &&
                <div className="col-2 text-right">
                  <Spinner color="light">
                    Loading...
                  </Spinner>
                </div>
              }
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
