import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import IMG_GIF from '../../assets/img/Spinner-1s-200px.gif';

const ModalUploading = ({ isOpen, onClose, textMessage }) => {
  return (
    <Modal 
      isOpen={isOpen} 
      toggle={onClose}
      backdrop={false}
      keyboard={false}
    >
      <ModalBody>
        <div>
          <div className="text-center mb-2">
            <img src={IMG_GIF} alt='' style={{ maxWidth: '100%' }} />
          </div>
          <div className="text-center mb-4">
            <h2>{ textMessage }</h2>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalUploading