import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import Swal from 'sweetalert2';
import DEFAULT_IMG from '../../assets/img/theme/picture-default.jpeg';
import Input from 'components/input/input';
import { GalleryService } from 'services';

const GalleryModal = ({ isOpen, onClose, onAdd }) => {
  const [title, setTitle] = useState('')
  const [avatarFile, setAvatarFile] = useState(null)
  const [avatarPreview, setAvatarPreview] = useState(DEFAULT_IMG)
  const [errors, setErrors] = useState('')
  const [isUpload, setIsUpload] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setTitle('')
      setAvatarFile(null)
      setAvatarPreview(DEFAULT_IMG)
      setErrors('')
    }
  }, [isOpen])
  

  const changeAvatarFileHandler = (e) => {
    e.preventDefault();
    let file = e.target.files[0];

    if (file) {
      let preview = URL.createObjectURL(file);

      setAvatarFile(file)
      setAvatarPreview(preview)
      // if (this.props.onChangeAvatar) {
      //   this.props.onChangeAvatar(file);
      // }
    }
  }

  const selectFileHanlder = () => {
    document.getElementById('avatarProfile').click();
  }

  const submitImageHandle = () => {
    console.log('save image')
    if (title && avatarFile) {
      setErrors('')
      saveImageHandle()
    } else {
      setErrors('Debe llenar todos los campos')
    }
  }

  const changeTitleHandler = (e) => {
    setTitle(e.target.value)
  }

  const saveImageHandle = async() => {
    try {
      // const { avatarFile, profile } = this.state;
      if (avatarFile) {
        const formData = new FormData();
        formData.append('photo', avatarFile);
        formData.append('title', title);

        setIsUpload(true)
        await GalleryService.save(formData);
        setIsUpload(false)
        // return response.data && response.data.status;
        Swal.fire({
          title: 'Imagen Guardada',
          text: 'La imagen se ha agregado a la galería',
          icon: 'success',
        })

        onAdd()
      }
    } catch (e) {
      console.log(e);
      setIsUpload(false)
      Swal.fire({
        title: 'Error',
        text: 'Ocurrio un error al intentar guardar la imagen',
        icon: 'error',
      })

      onAdd()
    }
  }

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        toggle={onClose}
        backdrop={false}
        keyboard={false}
      >
        <ModalHeader>Agregar imagen a Galería</ModalHeader>
        <ModalBody>
          { errors &&
            <div className="alert alert-danger">{errors}</div>
          }
          <form>
            <input 
              type="file" 
              style={{display: 'none'}}
              id="avatarProfile" 
              accept="image/jpeg, image/png, image/jpg" 
              onChange={ changeAvatarFileHandler } 
            />
            <div className="gallery-preview-image">
              <img src={avatarPreview} alt='preview' />
            </div>
            <div className="text-center">
              <button 
                type="button" 
                className="btn btn-info"
                onClick={selectFileHanlder}
              >
                Seleccionar imagen
              </button>
            </div>
            <Input 
              label="Título"
              name="title"
              onChange={changeTitleHandler}
            />
          </form>
          <div className="mt-5">
            { isUpload  ?
                (<div className="col-2 text-right">
                  <Spinner color="primary">
                    Loading...
                  </Spinner>
                </div>)
                :(<div className="row">
                  <div className="col-6">
                    <button type="button" className="btn btn-block btn-secondary" onClick={onClose}>Cancelar</button> 
                  </div>
                  <div className="col-6">
                    <button type="button" className="btn btn-block btn-primary" onClick={submitImageHandle}>Guardar</button> 
                  </div>
                </div>)
            }
            
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default GalleryModal