import React, { useEffect, useState } from 'react'
import Header from 'components/Headers/Header'
import ModalUploading from './ModalUploading'
import Input from 'components/input/input'
import { useHistory, useParams } from 'react-router-dom'
import Switch from "react-switch";
import Select from 'react-select'
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { InterviewService, VideoService, CategoryService } from 'services';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from 'sweetalert2';

import IMG_AVATAR from '../../assets/img/1280x720.svg';
import ModalUploadVideo from '../../components/ModalUploadVideo/ModalUploadVideo'

const toolbarOptions = {
  options: ['inline', 'link']
}

const interviewInit = {
  name: '',
  picture: '',
  external_video: '',
  related_interviews: [],
  resume: '',
  category: '',
  date_place_birth: '',
  degree: '',
  nationality: '',
  religion: '',
  father: '',
  father_birth: '',
  mother: '',
  mother_birth: '',
  siblings: '',
  is_active: false
}

const EditInterview = () => {
  const history = useHistory()
  const { id } = useParams()
  const [categories, setCategories] = useState([])
  const [interview, setInterview] = useState(interviewInit)
  const [categorySelected, setCategorySelected] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadingText, setUploadingText] = useState('')
  const [avatarFile, setAvatarFile] = useState(null)
  const [avatarPreview, setAvatarPreview] = useState(IMG_AVATAR)
  const [errors, setErrors] = useState({})
  const [optionsRelated, setOptionsRelated] = useState([])
  const [selectInterviews, setSelectInterviews] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [video1, setVideo1] = useState(null)
  const [video2, setVideo2] = useState(null)
  const [modalVideoOpen, setModalVideoOpen] = useState(false)
  const [uploadVideoActive, setUploadVideoActive] = useState(1)

  useEffect(() => {
    loadCategories()
    loadRelatedInterviews()
    loadInterview()
  }, [id])
  
  const loadCategories = async() => {
    try {
      const response = await CategoryService.list();
      setCategories(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const loadRelatedInterviews = async () => {
    try {
      let options = []
      const response = await InterviewService.list()
      for (let i = 0; i < response.data.length; i++) {
        const element = response.data[i];
        if (element._id !== id) {
          options.push({
            value: element._id,
            label: element.name
          })
        }
      }

      setOptionsRelated(options)
    } catch (error) {
      console.log(error)
    }
  }

  const loadInterview = async() => {
    try {
      const response = await InterviewService.detail(id)
      // console.log(response)
      let selCat = response.data.category ? response.data.category : null
      setCategorySelected(selCat)
      setInterview(response.data)
      if (response.data.url_picture) {
        setAvatarPreview(response.data.url_picture)
      }

      if (response.data.related_interviews) {
        let selectedOptions = []
        for (let i = 0; i < response.data.related_interviews.length; i++) {
          const element = response.data.related_interviews[i];
          selectedOptions.push({
            value: element._id,
            label: element.name
          })
        }

        setSelectInterviews(selectedOptions)
      }

      if (response.data.resume) {
        const contentBlock = htmlToDraft(response.data.resume);
        
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)))
      }

      if (response.data.videos && response.data.videos.length) {
        if(response.data.videos[0]) {
          setVideo1(response.data.videos[0])
        }

        if(response.data.videos[1]) {
          setVideo2(response.data.videos[1])
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const changePhotoFileHandler = (e) => {
    e.preventDefault();
    let file = e.target.files[0];

    if (file) {
      let preview = URL.createObjectURL(file);

      setAvatarFile(file)
      setAvatarPreview(preview)
    }
  }

  const selectFileHanlder = () => {
    document.getElementById('photoInterview').click();
  }

  const onChangeInputHandler = (e) => {
    let inputVal = e.target.value
    let inputName = e.target.name

    setInterview({
      ...interview,
      [`${inputName}`]: inputVal
    })
  }

  const onActiveToggle = () => {
    setInterview({
      ...interview,
      is_active: !interview.is_active
    })
  }

  const onEditorStateChangeHandle = (eState) => {
    setEditorState(eState)
  }

  const categoryChangeHandler = (e) => {
    console.log('categoryChangeHandler', e.target.value)
    let selected = categories.find(item => {
      return item._id === e.target.value 
    })

    console.log('selectedCategory::', selected)
    setCategorySelected(selected)
  }

  const onSaveImage = async (interviewID) => {
    try {
      if (avatarFile) {
        const formData = new FormData();
        formData.append('photo', avatarFile);

        const response = await InterviewService.upload_image(interviewID, formData);
        console.log('uploadImage: ', response.data)
        return response.data.status
      }
    } catch (e) {
      console.log(e);
      return false
    }
  }

  const removeFilePhoto = () => {
    setAvatarFile(null)
    setAvatarPreview(IMG_AVATAR)
  }

  const onChangeRelatedHandler = (newValue, actionMeta) => {
    setSelectInterviews(newValue)
  };

  const onCloseUploading = () => {
    setIsUploading(false)
  }

  const onSubmitHandler = () => {
    let isValidForm = true;
    let formErrors = {};
    if (!interview.name) {
      isValidForm = false;
      formErrors.name = "El campo es obligatorio"
    }

    if (!categorySelected) {
      isValidForm = false;
      formErrors.category = "Debe seleccionar una categoría"
    }

    if (isValidForm) {
      setErrors({})
      onSaveHandler()
    } else {
      setErrors(formErrors)
    }
  }

  const onSaveHandler = async () => {
    let paramsSave = { ...interview }
    if (categorySelected.key.toLowerCase() === 'especialistas') {
      paramsSave.date_place_birth = ''
      paramsSave.degree = ''
      paramsSave.nationality = ''
      paramsSave.religion = ''
      //resum html
      paramsSave.resume = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    } else {
      paramsSave.resume = ''
    }

    paramsSave.category = categorySelected._id
    paramsSave.related_interviews = []

    if (selectInterviews && selectInterviews.length) {
      for (let i = 0; i < selectInterviews.length; i++) {
        paramsSave.related_interviews.push(selectInterviews[i].value)
      }
    } 

    delete paramsSave.videos;
    delete paramsSave.url_picture;
    delete paramsSave.updated_at;
    delete paramsSave.created_at;

    try {
      setUploadingText('Guardando información...')
      setIsUploading(true)
      const response = await InterviewService.update(paramsSave);
      if (response.data.status) {
        //Guarda imagen
        if (avatarFile) {
          setUploadingText('Guardando imagen...')
          await onSaveImage(interview._id)
        }

        // let fileTitle1 = categorySelected.key.toLowerCase() === 'especialistas' ? 'Video 1': 'Video Histórico';
        // const fileVideo1 = document.getElementById('interviewVideo1')
        // if (fileVideo1.files && fileVideo1.files[0]) {
        //   setUploadingText(`Guardando ${fileTitle1}...`)
        //   await onSaveVideo(response.data._id, fileTitle1, fileVideo1.files[0])
        // }

        // let fileTitle2 = categorySelected.key.toLowerCase() === 'especialistas' ? 'Video 2': 'Video Reflexivo';
        // const fileVideo2 = document.getElementById('interviewVideo2')
        // if (fileVideo2.files && fileVideo2.files[0]) {
        //   setUploadingText(`Guardando ${fileTitle1}...`)
        //   await onSaveVideo(response.data._id, fileTitle2, fileVideo2.files[0])
        // }
      }
      setIsUploading(false)
      await Swal.fire({
        title: 'Información Guardada',
        text: 'La entrevista ha sido guardada',
        icon: 'success',
      })

      history.push('/admin/entrevistas')
    } catch (error) {
      console.log(error)
      setIsUploading(false)
      Swal.fire({
        title: 'Error',
        text: 'Ocurrio un error al guardar la información',
        icon: 'error',
      })
    }
  }

  const onSaveVideo = async (videoParams) => {
    try {
      if (videoParams._id) {
        const response = await VideoService.updateInfo(videoParams)
        return response.data
      } else {
        const response = await VideoService.saveInfo(videoParams)
        return response.data
      }
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const onSaveInfoVideo = async (videoNum, fileName) => {
    console.log('onSaveInfoVideo:', videoNum, fileName)
    try {
      if (videoNum === 1) {
        let fileTitle1 = categorySelected.key.toLowerCase() === 'especialistas' ? 'Video 1': 'Video Histórico';
        let data = {
          title: video1 ? video1.title : fileTitle1,
          interview: video1 ? video1.interview : id,
          file_name: fileName
        }

        if (video1) {
          data._id = video1._id
        }

        await onSaveVideo(data)
        
        await Swal.fire({
          title: 'Video actualizado',
          text: `El ${fileTitle1} fue actualizado`,
          icon: 'success',
        })

        window.location.reload(false);
      } else {
        let fileTitle2 = categorySelected.key.toLowerCase() === 'especialistas' ? 'Video 2': 'Video Reflexivo';
        let data = {
          title: video2 ? video2.title : fileTitle2,
          interview: video2 ? video2.interview : id,
          file_name: fileName
        }

        if (video2) {
          data._id = video2._id
        }

        await onSaveVideo(data)
        
        await Swal.fire({
          title: 'Video actualizado',
          text: `El ${fileTitle2} fue actualizado`,
          icon: 'success',
        })

        window.location.reload(false);
      }
    } catch (error) {
      console.log(error)
      Swal.fire({
        title: 'Error',
        text: 'Ocurrio un error al guardar la información del video',
        icon: 'error',
      })
    }
  }


  const openModalVideo = (videoNum) => {
    setUploadVideoActive(videoNum)
    setModalVideoOpen(true)
  }

  const closeModalVideo = () => {
    console.log('closeModalVideo');
    setModalVideoOpen(false)
  }

  const completeUploadVideoHandler = (fileName) => {
    onSaveInfoVideo(uploadVideoActive, fileName)
  }

  return (
    <>
      <Header title="Editar entrevista" isLoading={isLoading} />
      <div className="container-fluid mt--7">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Editar información</h3>
                    </div>
                </div>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="interview-photo mb-4">
                        <input 
                          type="file" 
                          style={{display: 'none'}}
                          id="photoInterview" 
                          accept="image/jpeg, image/png, image/jpg" 
                          onChange={ changePhotoFileHandler } 
                        />
                        <div className="interview-preview-img">
                          <img src={avatarPreview} alt='preview' />
                        </div>
                        <div className="text-center">
                          <button 
                            type="button" 
                            className="btn btn-sm btn-info"
                            onClick={selectFileHanlder}
                          >
                            Seleccionar imagen
                          </button>
                          { avatarFile &&
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary"
                              onClick={removeFilePhoto}
                            >
                              Eliminar
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="d-form-switch">
                        <label>
                          <span>Publicada</span>
                          <Switch onChange={onActiveToggle} checked={interview.is_active} />
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <Input 
                            label="Nombre*"
                            name="name"
                            value={interview.name}
                            onChange={onChangeInputHandler}
                            errorMessage={errors.name || ''}
                          />
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-control-component">
                            <label>Categoría*</label>
                            <select 
                              className="form-control" 
                              name="category"
                              value={categorySelected?._id || ''}
                              onChange={categoryChangeHandler}
                            >
                              <option>Selecciona una categoría</option>
                              { categories.map(item => {
                                return <option value={item._id} key={`cat-${item._id}`}>{item.title}</option>
                              }) }
                            </select>
                            { errors.category && ( <div className="form-error text-danger">{ errors.category }</div> ) }
                          </div>
                        </div>
                      </div>
                    </div>  
                  </div>
                  { categorySelected && categorySelected.key.toLowerCase() === 'especialistas' 
                    ? <div className="row mt-4">
                        <div className="col">
                          <label><strong>Resumen</strong></label>
                          <Editor 
                            editorState={editorState}
                            toolbar={toolbarOptions}
                            wrapperClassName="wrap-editor"
                            onEditorStateChange={onEditorStateChangeHandle}
                          />
                        </div>
                      </div>
                    : <div className="mt-4">
                        <div className="row">
                          <div className="col-md-6">
                            <Input 
                              label="Lugar y fecha de nacimiento"
                              name="date_place_birth"
                              value={interview.date_place_birth}
                              onChange={onChangeInputHandler}
                            />
                          </div>
                          <div className="col-md-6">
                            <Input 
                              label="Nacionalidad"
                              name="nationality"
                              value={interview.nationality}
                              onChange={onChangeInputHandler}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Input 
                              label="Estudios"
                              name="degree"
                              value={interview.degree}
                              onChange={onChangeInputHandler}
                            />
                          </div>
                          <div className="col-md-6">
                            <Input 
                              label="Religion"
                              name="religion"
                              value={interview.religion}
                              onChange={onChangeInputHandler}
                            />
                          </div>
                        </div>
                      </div>
                  }
                  <hr/>
                  <h3>Información del padre</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <Input 
                        label="Nombre"
                        name="father"
                        value={interview.father}
                        onChange={onChangeInputHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input 
                        label="Lugar y fecha de nacimiento"
                        name="father_birth"
                        value={interview.father_birth}
                        onChange={onChangeInputHandler}
                      />
                    </div>
                  </div>
                  <hr/>
                  <h3>Información de la madre</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <Input 
                        label="Nombre"
                        name="mother"
                        value={interview.mother}
                        onChange={onChangeInputHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input 
                        label="Lugar y fecha de nacimiento"
                        name="mother_birth"
                        value={interview.mother_birth}
                        onChange={onChangeInputHandler}
                      />
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-md-12">
                      <Input 
                        label="Hermanos"
                        name="siblings"
                        value={interview.siblings}
                        onChange={onChangeInputHandler}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="form-group form-control-component">
                        <label>Entrevistas asociadas</label>
                        <Select 
                          value={selectInterviews}
                          name="associated"
                          isMulti
                          options={optionsRelated}
                          onChange={onChangeRelatedHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-actions text-right mt-5'>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => history.push('/admin/entrevistas')}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={onSubmitHandler}
                    >
                      Actualizar información
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        { categorySelected && categorySelected.key.toLowerCase() === 'especialistas' ? 'Video 1' : 'Video Histórico'}
                      </h3>
                    </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <form id='formVideo1' encType="multipart/form-data">
                  { video1 &&
                    <div className="preview-video">
                      <video width="320" height="240" controls>
                        <source src={video1.video_url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  }

                  { !video1 &&
                    <p>Sin video</p>
                  }
                  
                  
                  <div className='form-actions text-right mt-5'>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => openModalVideo(1)}
                    >
                      Subir video
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        { categorySelected && categorySelected.key.toLowerCase() === 'especialistas' ? 'Video 2' : 'Video Reflexivo'}
                      </h3>
                    </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <form id='formVideo2'>
                  { video2 &&
                    <div className="preview-video">
                      <video width="320" height="240" controls>
                        <source src={ video2.video_url } type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  }

                  { !video2 &&
                    <p>Sin video</p>
                  }

                  <div className='form-actions text-right mt-5'>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => openModalVideo(2)}
                    >
                      Subir video
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
      <ModalUploading 
        isOpen={isUploading}
        onClose={onCloseUploading}
        textMessage={uploadingText}
      />

      <ModalUploadVideo 
        isOpen={modalVideoOpen}
        videoNum={uploadVideoActive}
        onClose={closeModalVideo}
        onComplete={completeUploadVideoHandler}
      />
    </>
  )
}

export default EditInterview