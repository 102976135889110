import HTTP from "./http.service";
import ROUTES_API from "const/routes-api.const";

class NotesService {
  async list() {
    return await HTTP.get(ROUTES_API.NOTES.LIST);
  }

  async detail(noteID) {
    return await HTTP.get(ROUTES_API.NOTES.DETAIL.replace(':id', noteID));
  }

  async save(params) {
    return await HTTP.post(ROUTES_API.NOTES.ADD, params);
  }

  async update(noteID, params) {
    return await HTTP.put(ROUTES_API.NOTES.UPDATE.replace(':id', noteID), params);
  }

  async updatePhoto(params) {
    return await HTTP.put(ROUTES_API.NOTES.UPDATE_PHOTO, params);
  }

  async delete(noteID) {
    return await HTTP.delete(ROUTES_API.NOTES.DELETE.replace(':id', noteID));
  }
}

const instance = new NotesService();
Object.freeze(instance);

export default instance;